import React, { useState } from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import { _theme } from './library';
import LaptopImg from '../assets/laptop_insights.png';

export default function PredictiveInsights() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const insights = [
    {
      quote: (
        <>
          “BetterLogiq <strong>consistently outperforms</strong> industry-leading ERP systems, top AI tech firms, and prestigious management consulting tools.”
        </>
      ),
      author: '―Chief Data Scientist\nSupply Chain Analytics,\nManaging over 50,000 SKUs ($2.5Bn)',
    },
    {
      quote: (
        <>
          Its <strong>unparalleled accuracy</strong> and reliability have set a new standard for excellence, empowering smarter, data-driven decisions with confidence and precision.”
        </>
      ),
      author: '―Chief Data Scientist\nSupply Chain Analytics,\nManaging over 50,000 SKUs ($2.5Bn)',
    },
  ];

  return (
    <Box sx={{ py: 4, backgroundColor: 'background.default' }}>
      <Grid container spacing={4} alignItems="center">
        {/* Left Column: Quote Box */}
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              backgroundColor: _theme().palette.primary.backgroundGrey,
              boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
              borderRadius: 4, // UPDATED from 2 to 4
              p: 3,
              position: 'relative',
              minHeight: 220,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h5" sx={{ textAlign: 'left', color: 'primary.dark' }}>
              {insights[currentIndex].quote}
            </Typography>

            <Box sx={{ height: 20 }} />

            <Typography
              variant="h6"
              sx={{
                textAlign: 'right',
                lineHeight: 1.5,
                color: 'primary.dark',
                whiteSpace: 'pre-line',
              }}
            >
              {insights[currentIndex].author.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 0,
                mt: 3,
              }}
            >
              <Box
                onClick={() => setCurrentIndex(0)}
                sx={{
                  width: 24,
                  height: 24,
                  cursor: 'pointer',
                  clipPath: 'polygon(0% 50%, 100% 0%, 100% 100%)',
                  backgroundColor: currentIndex === 1 ? 'grey.400' : _theme().palette.primary.main,
                  transition: 'background-color 0.3s ease',
                }}
              />
              <Box
                onClick={() => setCurrentIndex(1)}
                sx={{
                  width: 24,
                  height: 24,
                  cursor: 'pointer',
                  clipPath: 'polygon(100% 50%, 0% 100%, 0% 0%)',
                  backgroundColor: currentIndex === 0 ? 'grey.400' : _theme().palette.primary.main,
                  transition: 'background-color 0.3s ease',
                }}
              />
            </Box>
          </Box>

          <Box sx={{ mt: 2, textAlign: 'left' }}>
            <Button
              variant="contained"
              href="mailto:info@betterlogiq.com"
              sx={{ textTransform: 'none', backgroundColor: 'primary.main', color: '#fff' }}
            >
              GET IN TOUCH
            </Button>
          </Box>
        </Grid>

        {/* Right Column: Image */}
        <Grid item xs={12} md={7}>
          <Box
            component="img"
            src={LaptopImg}
            alt="Predictive Insights"
            sx={{
              width: '100%',
              borderRadius: 4, // UPDATED from 2 to 4
              boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}