import React, { useState, useRef, useEffect } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import {
  Box,
  Link as MuiLink,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { Element, scroller } from 'react-scroll';
import { PinkButton, _theme } from './library';
import AOS from 'aos';
import logo_color from '../assets/bl_logo_color.svg';
import logo_grey from '../assets/bl_logo_grey.svg';
import LoginModal from './LoginModal';
import LoginIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

export default function Navbar() {
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [navBackground, setNavBackground] = useState(false);
  const headerRef = useRef(null);

  // Helper: compute desired top position for targets (navbar height + 20px margin)
  const getDesiredTop = () => (headerRef.current ? headerRef.current.offsetHeight + 20 : 140);

  // Fixed offset used for initial scrolling (negative value)
  const FIXED_OFFSET = -140;

  // Update navbar background based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      setNavBackground(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // After starting scroll, check target's position and adjust if needed.
  const adjustScrollDuring = (targetName) => {
    const desiredTop = getDesiredTop();
    const tolerance = 5; // pixels tolerance
    const intervalId = setInterval(() => {
      const targetElem = document.querySelector(`[name="${targetName}"]`);
      if (targetElem) {
        const rect = targetElem.getBoundingClientRect();
        const diff = desiredTop - rect.top;
        if (Math.abs(diff) <= tolerance) {
          clearInterval(intervalId);
        } else {
          scroller.scrollMore(diff, { duration: 300, smooth: true });
        }
      } else {
        clearInterval(intervalId);
      }
    }, 200);
  };

  // Simple scroll handler: trigger scroll with fixed offset then adjust on the fly.
  const handleScroll = (to) => {
    scroller.scrollTo(to, {
      duration: 500,
      smooth: true,
      offset: FIXED_OFFSET,
    });
    setTimeout(() => {
      adjustScrollDuring(to);
    }, 200);
  };

  const handleScrollToTop = () => {
    scroller.scrollTo('top', {
      duration: 800,
      smooth: true,
      offset: FIXED_OFFSET,
    });
    setTimeout(() => {
      adjustScrollDuring('top');
    }, 200);
  };

  const linkColor = navBackground ? _theme().palette.primary.dark : 'white';

  return (
    <>
      {/* Invisible element as scroll target for top */}
      <Element name="top" />
      <Box
        ref={headerRef}
        sx={{
          position: 'fixed',
          top: '1.25rem',
          left: '50%',
          transform: navBackground
            ? 'translateX(-50%) translateY(0) scale(1)'
            : 'translateX(-50%) translateY(-1.25rem) scale(0.95)',
          opacity: navBackground ? 1 : 0.8,
          zIndex: 1000,
          bgcolor: navBackground ? 'rgba(255,255,255,0.8)' : 'transparent',
          borderRadius: 3,
          boxShadow: '0px 2px 10px rgba(0,0,0,0.1)',
          px: _theme().spacing(2),
          py: _theme().spacing(1),
          width: '90%',
          maxWidth: 1400,
          transition: 'transform 0.7s ease, opacity 0.7s ease, background-color 0.5s ease',
        }}
      >
        <Grid2 container direction="row" justifyContent="space-between" alignItems="center">
          {/* Logo Section */}
          <Grid2 xs="auto">
            <MuiLink
              sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
              onClick={handleScrollToTop}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: { xs: 180, md: 240 },
                  height: 'auto',
                }}
              >
                <img
                  src={logo_grey}
                  alt="BetterLogiq grey logo"
                  width="100%"
                  style={{
                    transition: 'opacity 0.5s ease',
                    opacity: navBackground ? 0 : 1,
                  }}
                />
                <img
                  src={logo_color}
                  alt="BetterLogiq color logo"
                  width="100%"
                  style={{
                    transition: 'opacity 0.5s ease',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    opacity: navBackground ? 1 : 0,
                  }}
                />
              </Box>
            </MuiLink>
          </Grid2>

          {/* Desktop Navigation Links */}
          <Grid2 xs="auto" sx={{ display: { xs: 'none', md: 'flex' }, gap: 4, alignItems: 'center' }}>
            <MuiLink
              component="span"
              sx={{ cursor: 'pointer', fontWeight: 'bold', color: linkColor }}
              onClick={() => handleScroll('product')}
            >
              Product
            </MuiLink>
            <MuiLink
              component="span"
              sx={{ cursor: 'pointer', fontWeight: 'bold', color: linkColor }}
              onClick={() => handleScroll('performance')}
            >
              Why
            </MuiLink>
            <MuiLink
              component="span"
              sx={{ cursor: 'pointer', fontWeight: 'bold', color: linkColor }}
              onClick={() => handleScroll('working')}
            >
              About Us
            </MuiLink>
            <MuiLink
              component="span"
              sx={{ cursor: 'pointer', fontWeight: 'bold', color: linkColor }}
              onClick={() => handleScroll('questions')}
            >
              FAQ
            </MuiLink>
            <MuiLink
              onClick={() => setLoginOpen(true)}
              sx={{
                cursor: 'pointer',
                fontWeight: 'bold',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: linkColor,
              }}
            >
              <LoginIcon sx={{ fontSize: '1.25rem' }} />
              Login
            </MuiLink>
            <MuiLink href="mailto:info@betterlogiq.com" sx={{ textDecoration: 'none' }}>
              <PinkButton>Contact</PinkButton>
            </MuiLink>
          </Grid2>

          {/* Mobile Hamburger Menu */}
          <Grid2 xs="auto" sx={{ display: { xs: 'flex', md: 'none' } }}>
            <MobileMenu linkColor={linkColor} handleScroll={handleScroll} setLoginOpen={setLoginOpen} />
          </Grid2>
        </Grid2>
      </Box>
      <LoginModal open={isLoginOpen} onClose={() => setLoginOpen(false)} />
    </>
  );
}

function MobileMenu({ linkColor, handleScroll, setLoginOpen }) {
  const [open, setOpen] = useState(false);

  // Define a mapping for nav labels
  const navLabels = {
    product: 'Product',
    performance: 'Why',
    working: 'About Us',
    questions: 'FAQ',
  };

  const toggleDrawer = (state) => () => {
    setOpen(state);
  };

  return (
    <>
      <IconButton onClick={toggleDrawer(true)} sx={{ color: linkColor }}>
        <MenuIcon sx={{ fontSize: '2rem' }} />
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: '100%',
            height: '100%',
            backgroundColor: _theme().palette.primary.backgroundGrey,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
        }}
      >
        {/* Top bar with close icon */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={toggleDrawer(false)}>
            <CloseIcon sx={{ fontSize: '2rem', color: _theme().palette.primary.dark }} />
          </IconButton>
        </Box>
        {/* Navigation list */}
        <Box>
          <List>
            {['product', 'performance', 'working', 'questions'].map((page) => (
              <ListItem
                key={page}
                button
                onClick={() => {
                  handleScroll(page);
                  setOpen(false);
                }}
              >
                <ListItemText
                  primary={
                    <Typography variant="h3" sx={{ color: _theme().palette.primary.dark, fontWeight: 'bold' }}>
                      {navLabels[page]}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>

        {/* Bottom fixed area for Login and Contact */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              setLoginOpen(true);
              setOpen(false);
            }}
          >
            <LoginIcon sx={{ fontSize: '1.75rem', color: _theme().palette.primary.dark, mr: 0.5 }} />
            <Typography variant="h3" sx={{ color: _theme().palette.primary.dark, fontWeight: 'bold' }}>
              Login
            </Typography>
          </Box>
          <Box component="a" href="mailto:info@betterlogiq.com" onClick={() => setOpen(false)}>
            <PinkButton
              sx={{
                fontSize: { xs: '1.75rem', md: '2.5rem' },
                px: { xs: 2, md: 3 },
                py: { xs: 1, md: 1.5 },
              }}
            >
              Contact
            </PinkButton>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}