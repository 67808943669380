import * as React from 'react';
import { useState } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material';
import { LinkedIn } from '@mui/icons-material';
import { _theme } from './library';

export default function Footer() {
  // State for which modal is open: 'terms', 'privacy', or null.
  const [openModal, setOpenModal] = useState(null);

  const handleOpenModal = (type) => {
    setOpenModal(type);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  return (
    <Box
      sx={{
        bgcolor: 'primary.dark',
        color: 'white',
        pt: 6,
        pb: 2,
        px: { xs: 2, md: 8 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Center everything
        textAlign: 'center',
      }}
    >
      {/* Top row: Headline, Subtext, and LinkedIn Icon */}
      <Box sx={{ maxWidth: '800px' }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1 }}>
          Follow Us On LinkedIn
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Actionable insights and news for optimizing operations using predictive forecasting.
        </Typography>
        {/* LinkedIn Icon Button */}
        <IconButton
          href="https://www.linkedin.com/company/betterlogiq/posts/?feedView=all"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            backgroundColor: 'white',
            color: 'primary.dark',
            p: 1.5,
            '&:hover': { backgroundColor: 'grey.300' },
          }}
        >
          <LinkedIn fontSize="3.29rem" />
        </IconButton>
      </Box>

      {/* Bottom row: Terms/Privacy + Copyright */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="body2">
          <Box
            component="span"
            onClick={() => handleOpenModal('terms')}
            sx={{ cursor: 'pointer', mx: 1, textDecoration: 'underline' }}
          >
            Terms of Use
          </Box>
          |
          <Box
            component="span"
            onClick={() => handleOpenModal('privacy')}
            sx={{ cursor: 'pointer', mx: 1, textDecoration: 'underline' }}
          >
            Privacy Policy
          </Box>
          |
          <Box component="span" sx={{ mx: 1 }}>
            © 2025 BetterLogiq. All rights reserved.
          </Box>
        </Typography>
      </Box>

      {/* Terms of Use Dialog */}
      <Dialog open={openModal === 'terms'} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ color: _theme().palette.primary.dark, fontWeight: 'bold' }}>
          Terms of Use
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: _theme().palette.primary.dark }}>
            These Terms of Use are standard for websites. By using this site, you agree to abide by all
            applicable laws and regulations. Your continued access constitutes your acceptance of these
            terms.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Privacy Policy Dialog */}
      <Dialog open={openModal === 'privacy'} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ color: _theme().palette.primary.dark, fontWeight: 'bold' }}>
          Privacy Policy
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: _theme().palette.primary.dark }}>
            This Privacy Policy outlines how we collect, use, and protect your information when you
            visit our website. We respect your privacy and are committed to protecting your personal
            data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}