import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';
import { _theme } from './library';
import Forecasts from '../assets/product/lightning_icon.svg';
import Dashboard from '../assets/product/file_input.svg';
import SmartModel from '../assets/product/headset.svg';
import Folder from '../assets/product/goal.svg';
import Puzzle from '../assets/product/puzzle.svg';
import Support from '../assets/product/lightbulb_gear.svg';

const highlights = [
  {
    icon: Forecasts,
    title: 'AI-Driven Forecasts',
    description: (
      <>
        Leverage <strong>AI-powered demand forecasting</strong> to enhance <strong>supply chain efficiency</strong> and inventory planning. Our <strong>machine learning models</strong> adapt to market shifts, ensuring accurate, optimized forecasts that reduce waste and maximize profitability.
      </>
    ),
  },
  {
    icon: Dashboard,
    title: 'Smart Model Preprocessing',
    description: (
      <>
        Rapid, integrated <strong>data validation</strong> catches errors early, ensuring clean, reliable inputs that improve <strong>forecast accuracy</strong> and prevent costly mistakes.
      </>
    ),
  },
  {
    icon: SmartModel,
    title: 'Expert Support',
    description: (
      <>
        Get more than basic troubleshooting—our <strong>data scientists</strong> help refine models, interpret forecasts, and suggest <strong>data enrichment strategies</strong> to improve accuracy and drive better business decisions.
      </>
    ),
  },
  {
    icon: Folder,
    title: 'Flexible Forecasting Targets',
    description: (
      <>
        Customize forecasting time horizons to align with your <strong>business objectives</strong>, whether optimizing short-term operations or planning for long-term growth. Gain the flexibility to make data-driven decisions with confidence.
      </>
    ),
  },
  {
    icon: Puzzle,
    title: 'Native Data Store',
    description: (
      <>
        Access high-quality <strong>external data</strong> to enrich your models with <strong>market trends</strong>, and other key insights—improving forecast accuracy and decision-making.
      </>
    ),
  },
  {
    icon: Support,
    title: 'From Analytics to Insight',
    description: (
      <>
        Our intelligent overlays embed <strong>decades of real-world data science expertise</strong> directly into the platform, enhancing forecasts with advanced modeling techniques for greater accuracy and reliability.
      </>
    ),
  },
];

export default function ProductHighlights() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);
  const containerRef = useRef(null);

  // When a dot is clicked, disable auto-scroll.
  const handleDotClick = (index) => {
    if (index === currentIndex) return;
    setAutoScrollEnabled(false);
    setFade(false);
    setTimeout(() => {
      setCurrentIndex(index);
      setFade(true);
    }, 300); // 300ms fade transition
  };

  // Auto-scroll: cycle through the highlights every 5 seconds if enabled.
  useEffect(() => {
    let interval;
    if (autoScrollEnabled) {
      interval = setInterval(() => {
        setFade(false);
        setTimeout(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % highlights.length);
          setFade(true);
        }, 300);
      }, 5000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [autoScrollEnabled]);

  // IntersectionObserver to check if the component is in view.
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // Only one element is being observed.
        const entry = entries[0];
        // Enable auto-scroll only if the element is at least 50% visible.
        if (entry.intersectionRatio >= 0.5) {
          setAutoScrollEnabled(true);
        } else {
          setAutoScrollEnabled(false);
        }
      },
      { threshold: 0.5 }
    );
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, []);

  const { icon, title, description } = highlights[currentIndex];

  return (
    <Box ref={containerRef}>
      <Grid container spacing={4} alignItems="center" sx={{ my: 4, px: 2 }}>
        {/* Left column: static text (approx 40% width) */}
        <Grid item xs={12} md={5} sx={{ mb: { xs: 6, md: 0 } }}>
          <Typography variant="h3" sx={{ fontWeight: 'bold', mb: 2, color: 'primary.dark' }}>
            Advanced Modeling, Better Insights.
          </Typography>
          <Typography variant="h6" sx={{ color: 'primary.dark', mb: 2 }}>
            Powerful forecasting and optimization tools—<strong>built for business leaders</strong>, not just data scientists—to drive smarter decisions and better outcomes.
          </Typography>
          {/* Read About Us Button */}
          <ScrollLink to="working" smooth={true} duration={500} offset={-120}>
            <Button variant="contained" sx={{ textTransform: 'none', backgroundColor: 'primary.main', color: '#fff' }}>
              READ ABOUT US
            </Button>
          </ScrollLink>
        </Grid>

        {/* Right column: interactive highlight card (approx 60% width) */}
        <Grid item xs={12} md={7}>
          <Box
            sx={{
              position: 'relative',
              maxWidth: '100%',
              p: 3,
              textAlign: 'left',
              background: _theme().palette.primary.backgroundGrey,
              border: `1px solid ${_theme().palette.neutral[300]}`,
              borderRadius: 4,
              boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
            }}
          >
            <Box sx={{ position: 'absolute', top: -30, left: 20 }}>
              <img src={icon} alt={title} style={{ height: 80, width: 'auto', objectFit: 'contain' }} />
            </Box>
            <Box sx={{ mt: '50px', transition: 'opacity 0.5s ease-in-out', opacity: fade ? 1 : 0 }}>
              <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1, color: 'primary.dark' }}>
                {title}
              </Typography>
              <Typography variant="h5" sx={{ color: 'primary.dark' }}>
                {description}
              </Typography>
            </Box>
            {/* Dot navigation aligned to the right */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 1,
                position: 'absolute',
                bottom: 12,
                right: 12,
              }}
            >
              {highlights.map((_, index) => (
                <Box
                  key={index}
                  onClick={() => handleDotClick(index)}
                  sx={{
                    width: 10,
                    height: 10,
                    borderRadius: '50%',
                    backgroundColor: index === currentIndex ? 'primary.main' : 'grey.400',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                  }}
                />
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}