import React from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@mui/system';
import LogoSAP from '../assets/sap_gray.png';
import LogoBlueYonder from '../assets/blue_yonder_gray.svg';
import LogoOracle from '../assets/oracle_gray.png';
import LogoKinaxis from '../assets/kinaxis_gray.png';
import LogoInfor from '../assets/infor_gray.png';
import LogoCoupa from '../assets/coupa_gray.svg';
import LogoMicrosoft from '../assets/microsoft_gray.png';

// Array of logos
const logos = [
  LogoSAP,
  LogoKinaxis,
  LogoCoupa,
  LogoBlueYonder,
  LogoOracle,
  LogoInfor,
  LogoMicrosoft,
];

// Keyframes for continuous scrolling effect
const scroll = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

export default function ScrollingLogos() {
  return (
    <Box
      sx={{
        overflow: 'hidden',
        backgroundColor: '#f7f7f7',
        py: 2,
      }}
    >
      {/* Title */}
      <Typography 
        variant="subtitle1" 
        sx={{ textAlign: 'center', mb: 2, color: 'primary.dark', fontWeight: 'bold' }}
      >
        SEAMLESSLY INTEGRATES WITH:
      </Typography>
      
      {/* Outer container */}
      <Box sx={{ overflow: 'hidden', whiteSpace: 'nowrap', display: 'flex' }}>
        <Box
          sx={{
            display: 'inline-flex',
            gap: 6, // spacing between logos
            width: 'max-content',
            minWidth: '100vw', // ensures container fills the viewport width
            animation: `${scroll} 30s linear infinite`,
            willChange: 'transform',
            backfaceVisibility: 'hidden',
            transform: 'translate3d(0, 0, 0)',
          }}
        >
          {/* Duplicate logos for seamless scrolling */}
          {[...logos, ...logos].map((logo, index) => (
            <Box
              key={index}
              sx={{
                flexShrink: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 60,
              }}
            >
              <img
                src={logo}
                alt={`logo-${index}`}
                style={{
                  height: logo === LogoKinaxis ? 38 : 45,
                  width: 'auto',
                  objectFit: 'contain',
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}