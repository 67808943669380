import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { _theme } from './library';

const challenges = [
  {
    text: (
      <>
        BetterLogiq was born from a simple realization: <strong>most enterprise demand forecasting tools aren’t built for today’s supply chain complexity.</strong> 
        <br /><br />
        As former consultants for <strong>GE, Wabtec, Kraft Heinz</strong>, and other industry leaders, our co-founders spent years solving data-driven challenges. But the real <strong>a-ha moment</strong> came while working with a major industrials firm on supply chain financing.
      </>
    ),
  },
  {
    text: (
      <>
        This company struggled with demand forecasting—the <strong>built-in ERP models</strong> were too basic, unable to handle the complexity of matching thousands of SKUs to the right predictive models. Poor forecasts led to excess inventory, stockouts, and massive inefficiencies.
        <br /><br />
        They needed a <strong>smarter, more scalable approach</strong>—but tackling this problem at scale is computationally intensive and costly if done wrong.
      </>
    ),
  },
  {
    text: (
      <>
        We helped them <strong>develop a forecasting methodology</strong> that optimized demand predictions across their vast product catalog, unlocking <strong>over $30 million in annual savings</strong>. That’s when we knew: the market needed a better solution.
      </>
    ),
  },
  {
    text: (
      <>
        <strong>What We Do</strong>
        <br />
        BetterLogiq delivers <strong>AI-powered demand forecasting</strong> designed for the complexity of modern enterprise supply chains. We help large organizations: 
        <br />
        <Box sx={{ display: 'flex', alignItems: 'flex-start', ml: 2 }}>
          <CheckIcon sx={{ color: 'primary.main', mr: 1 }} />
          <span><strong>Match the right models to the right products</strong> for more accurate forecasts.</span>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', ml: 2 }}>
          <CheckIcon sx={{ color: 'primary.main', mr: 1 }} />
          <span><strong>Reduce inventory risk</strong> by balancing stock levels efficiently.</span>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', ml: 2 }}>
          <CheckIcon sx={{ color: 'primary.main', mr: 1 }} />
          <span><strong>Cut infrastructure costs</strong> with optimized, scalable forecasting models.</span>
        </Box>
      </>
    ),
  },
  {
    text: (
      <>
        With <strong>enterprise-grade AI, seamless integrations, and expert support</strong> we give businesses the tools to <strong>forecast smarter, operate leaner, and drive real impact</strong>. 
        <br /><br />
        <strong>Ready to transform your forecasting?</strong> Let’s talk.
      </>
    ),
  },
];

export default function AboutUs() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const handleDotClick = (index) => {
    if (index === selectedIndex) return;
    setFade(false);
    setTimeout(() => {
      setSelectedIndex(index);
      setFade(true);
    }, 300); // 300ms fade transition
  };

  return (
    <Box sx={{
      backgroundColor: 'transparent', // Let the Home.js background show
      px: { xs: 2, md: 8 },
    }}>
      {/* Headings */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h3" sx={{ fontWeight: 'bold', color: 'primary.dark' }}>
          The Challenge That Started It All
        </Typography>
      </Box>

      {/* Rounded Text Box Card */}
      <Box
        sx={{
          backgroundColor: _theme().palette.primary.backgroundGrey,
          boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
          borderRadius: 2,
          p: 3,
          width: '100%',
          transition: 'opacity 0.5s ease-in-out',
          opacity: fade ? 1 : 0,
          position: 'relative', // Enables absolute positioning for dots
        }}
      >
        <Typography variant="h5" sx={{ textAlign: 'left', color: 'primary.dark' }}>
          {challenges[selectedIndex].text}
        </Typography>

        {/* Dot Navigation Inside the Card at Bottom-Right */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 1,
            position: 'absolute',
            bottom: 12, // Adjust positioning from bottom
            right: 12, // Adjust positioning from right
          }}
        >
          {challenges.map((_, index) => (
            <Box
              key={index}
              onClick={() => handleDotClick(index)}
              sx={{
                width: 10,
                height: 10,
                borderRadius: '50%',
                backgroundColor: index === selectedIndex ? 'primary.main' : 'grey.400',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease',
              }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}