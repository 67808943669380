import React, { useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { _theme } from './library';
import Solution1 from '../assets/auto_store_lady.jpg';
import Solution2 from '../assets/another_warehouse.jpg';
import Solution3 from '../assets/auto_warehouse.jpg';

const solutions = [
  {
    image: Solution1,
    title: 'Shifting Demand?',
    description:
      'Anticipate trends with AI-driven insights to stay ahead of changing preferences.',
  },
  {
    image: Solution2,
    title: 'Inventory Issues?',
    description:
      'Reduce stockouts and overstock with AI-powered forecasting.',
  },
  {
    image: Solution3,
    title: 'Operational Risk?',
    description:
      'Reduce risk with data-backed decisions that improve stability and resilience.',
  },
];

export default function TailoredSolutions() {
  const isMobile = useMediaQuery(_theme().breakpoints.down('sm'));
  // Default active index is 0 for both mobile and desktop.
  const [activeIndex, setActiveIndex] = useState(0);

  const handleDesktopEnter = (index) => {
    setActiveIndex(index);
  };

  const handleDesktopLeave = () => {
    setActiveIndex(0);
  };

  const handleMobileClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <Box sx={{ py: 4, backgroundColor: 'background.paper', px: { xs: 2, md: 8 } }}>
      <Typography
        variant="h2"
        sx={{ textAlign: 'center', fontWeight: 'bold', mb: 4, color: 'primary.dark' }}
      >
        Built for Enterprise Supply Chains
      </Typography>
      <Box
        sx={{ 
          display: { xs: 'block', md: 'flex' }, 
          gap: 2, 
          transition: 'all 0.7s ease' 
        }}
      >
        {solutions.map((solution, index) => (
          <Box
            key={index}
            {...(isMobile
              ? { onClick: () => handleMobileClick(index) }
              : { onMouseEnter: () => handleDesktopEnter(index), onMouseLeave: handleDesktopLeave })}
            sx={{
              flex: { md: activeIndex === index ? 1.5 : 1 },
              width: { xs: '100%', md: 'auto' },
              mb: { xs: 2, md: 0 },
              position: 'relative',
              overflow: 'hidden',
              borderRadius: 4,
              cursor: 'pointer',
              transition: 'all 0.7s ease',
              height: { xs: activeIndex === index ? 250 : 100, md: 300 },
              filter: activeIndex !== index ? 'grayscale(100%)' : 'none',
              opacity: activeIndex !== index ? 0.5 : 1,
            }}
          >
            <Box
              component="img"
              src={solution.image}
              alt={solution.title}
              sx={{
                width: '100%',
                height: { xs: '100%', md: 300 },
                objectFit: 'cover',
                transition: 'all 0.7s ease',
                borderRadius: 'inherit',
                display: 'block',
              }}
            />
            {activeIndex === index && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background: 'linear-gradient(to bottom, rgba(0,0,0,0) 35%, rgba(0,0,0,0.75) 100%)',
                  pointerEvents: 'none',
                }}
              />
            )}
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                p: 2,
                color: 'white',
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 'bold',
                  position: 'absolute',
                  left: 16,
                  ...(isMobile
                    ? {
                        // On mobile, if active, align title to top; if inactive, align near bottom.
                        top: activeIndex === index ? '16px' : 'calc(100% - 48px)',
                        transition: 'top 0.7s ease',
                      }
                    : {
                        bottom: 16,
                        transition: activeIndex === index
                          ? 'transform 0.7s ease 0.7s'
                          : 'transform 0.7s ease 0s',
                        transform: activeIndex === index ? 'translateY(-44px)' : 'translateY(0)',
                      }),
                }}
              >
                {solution.title}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  position: 'absolute',
                  left: 16,
                  bottom: 16,
                  pr: 2,
                  transition: activeIndex === index
                    ? 'opacity 0.7s ease 0.7s, transform 0.7s ease 0.7s'
                    : 'opacity 0s, transform 0s',
                  opacity: activeIndex === index ? 1 : 0,
                  transform: activeIndex === index ? 'translateY(0)' : 'translateY(30px)',
                }}
              >
                {solution.description}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}