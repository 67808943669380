import React, { useState } from 'react';
import {
  Box,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import { _theme } from './library';
import logo from '../../../assets/logo.svg';
import logo_text from '../../../assets/logo_text.svg';

export default function LoginModal({ open, onClose }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);

  const handleLoginClick = (e) => {
    e.preventDefault();
    // Simulated user email check (replace with your actual authentication logic)
    const validEmails = ['user@example.com', 'admin@betterlogiq.com'];
    if (!validEmails.includes(email)) {
      setShowError(true); // Trigger the error popup
    }
  };

  const backdropStyle = { backgroundColor: _theme().palette.primary.main };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="xs"
        fullWidth
        BackdropProps={{ style: backdropStyle }}
        PaperProps={{ sx: { borderRadius: 4 } }}
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img src={logo} alt="BetterLogiq logo" width={40} style={{ marginRight: 10 }} />
            <img src={logo_text} alt="BetterLogiq logo" width={180} />
          </Box>
        </DialogTitle>

        <DialogContent sx={{ pt: 4 }}>
          <Box sx={{ mt: 2 }}>
            <form onSubmit={handleLoginClick}>
              {/* Email Input */}
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                sx={{ mb: 2 }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              {/* Password Input */}
              <TextField
                fullWidth
                label="Password"
                type="password"
                variant="outlined"
                sx={{ mb: 3 }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              {/* Button Row: Two lines of text on the left, Login button on the right */}
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                  }}
                >
                  <Typography variant="body2" sx={{ color: _theme().palette.primary.dark }}>
                    Don't have an account?
                  </Typography>
                  <Typography variant="body2">
                    <a
                      href="mailto:info@betterlogiq.com"
                      style={{
                        color: _theme().palette.primary.main,
                        textDecoration: 'none',
                        fontWeight: 'bold',
                      }}
                    >
                      Contact Us
                    </a>
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    sx={{
                      backgroundColor: _theme().palette.primary.dark,
                      borderRadius: 3,
                      p: 1,
                      m: 1,
                      color: '#fff',
                      '&:hover': { backgroundColor: _theme().palette.primary.main },
                    }}
                  >
                    Login
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Warning Popup for Invalid Email */}
      <Dialog
        open={showError}
        onClose={() => setShowError(false)}
        maxWidth="xs"
        fullWidth
        BackdropProps={{ style: backdropStyle }}
        PaperProps={{ sx: { borderRadius: 4 } }}
      >
        <DialogTitle sx={{ color: '#E01A4F', fontWeight: 'bold' }}>
          Email Not Recognized
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: _theme().palette.primary.dark }}>
            The email you entered is not associated with an account. Please contact us for assistance.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button href="mailto:info@betterlogiq.com" sx={{ color: _theme().palette.primary.main }}>
            Contact Us
          </Button>
          <Button onClick={() => setShowError(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}