import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { _theme } from './library'; // Import theme helper
import CountUp from 'react-countup';

export default function ProductPerformance() {
  return (
    // Outer container spans full width with the grey background and reduced vertical padding
    <Box sx={{ width: '100%', backgroundColor: _theme().palette.primary.backgroundGrey, py: 4 }}>
      {/* Inner container centers the content */}
      <Box sx={{ maxWidth: '1200px', mx: 'auto', px: { xs: 2, md: 4 } }}>
        <Grid container spacing={4} justifyContent="center">
          {/* Stat 1: Typical forecast improvement */}
          <Grid item xs={6} md={3}>
            <Typography variant="h1" sx={{ fontWeight: 'bold', color: 'primary.dark', textAlign: 'center' }}>
              <CountUp
                start={0}
                end={30}
                duration={2}
                suffix="%"
                enableScrollSpy={true}
                scrollSpyOnce={true}
              />
            </Typography>
            <Typography variant="subtitle1" sx={{ color: 'primary.dark', textAlign: 'center' }}>
              Typical forecast improvement
            </Typography>
          </Grid>

          {/* Stat 2: Average Onboarding Time */}
          <Grid item xs={6} md={3}>
            <Typography variant="h1" sx={{ fontWeight: 'bold', color: 'primary.dark', textAlign: 'center' }}>
              <CountUp
                start={0}
                end={14}
                duration={2}
                suffix=" Days"
                enableScrollSpy={true}
                scrollSpyOnce={true}
              />
            </Typography>
            <Typography variant="subtitle1" sx={{ color: 'primary.dark', textAlign: 'center' }}>
              Average Onboarding Time
            </Typography>
          </Grid>

          {/* Stat 3: Typical SKUs per forecast */}
          <Grid item xs={6} md={3}>
            <Typography variant="h1" sx={{ fontWeight: 'bold', color: 'primary.dark', textAlign: 'center' }}>
              <CountUp
                start={0}
                end={10000}
                duration={2}
                separator=","
                enableScrollSpy={true}
                scrollSpyOnce={true}
              />
              +
            </Typography>
            <Typography variant="subtitle1" sx={{ color: 'primary.dark', textAlign: 'center' }}>
              Typical SKUs / forecast
            </Typography>
          </Grid>

          {/* Stat 4: Typical Run-time Improvement */}
          <Grid item xs={6} md={3}>
            <Typography variant="h1" sx={{ fontWeight: 'bold', color: 'primary.dark', textAlign: 'center' }}>
              <CountUp
                start={0}
                end={95}
                duration={2}
                suffix="%"
                enableScrollSpy={true}
                scrollSpyOnce={true}
              />
            </Typography>
            <Typography variant="subtitle1" sx={{ color: 'primary.dark', textAlign: 'center' }}>
              Typical Run-time Improvement
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}