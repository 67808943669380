import { Typography, Button } from '@mui/material';
import { theme } from '../../../App';

export const _theme = () => theme;

export const boxShadow = '0px 30px 30px rgba(0, 0, 0, 0.4)';

export const PinkButton = ({ sx, children }) => (
	<Button
		variant='contained'
		href={'mailto:info@betterlogiq.com'}
		sx={{
			background: '#E01A4F',
			borderRadius: 3,
			p: theme.spacing(1, 3),
			m: 1,
			float: 'right',
			...sx,
		}}>
		{children}
	</Button>
);

export const SectionTitle = ({ sx, children }) => (
	<Typography
		variant='h6'
		sx={{
			textAlign: 'center',
			letterSpacing: 2,
			textTransform: 'uppercase',
			color: 'primary.main',
			fontWeight: 700,
			...sx,
		}}>
		{children}
	</Typography>
);

export const SectionHead = ({ sx, children }) => (
	<Typography
		variant='h3'
		sx={{
			textAlign: 'center',
			p: 1,
			...sx,
		}}>
		{children}
	</Typography>
);
