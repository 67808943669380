import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { _theme } from './library'; // Import theme helper
import CaseStudyImg from '../assets/warehouse_lady.jpg';

export default function CaseStudy() {
  const isMobile = useMediaQuery(_theme().breakpoints.down('sm'));

  // Define concise labels for mobile display and full labels for desktop.
  const conciseLabels = [
    "ANNUAL DELIVERED VALUE",
    "FORECAST ACCURACY IMPROVEMENT",
    "BIAS IMPROVEMENT",
    "WORKING CAPITAL REDUCTION",
    "FTEs REALLOCATED",
    "INCREASED FILL RATE",
    "RUNTIME REDUCTION"
  ];

  const fullLabels = [
    "ANNUAL DELIVERED VALUE",
    "ABSOLUTE FORECAST ACCURACY IMPROVEMENT",
    "ABSOLUTE BIAS IMPROVEMENT",
    "REDUCTION IN WORKING CAPITAL FOR INVENTORY",
    "REALLOCATION OF FTEs",
    "INCREASED FILL RATE",
    "REDUCTION IN MODEL RUNTIME"
  ];

  return (
    <Box
      sx={{
        py: 2,
        px: { xs: 2, md: 8 },
        backgroundColor: 'transparent',
        maxWidth: '1000px',
        mx: 'auto',
      }}
    >
      <Grid container spacing={4} alignItems="stretch">
        {/* Left Column: Image */}
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: 'flex',
            position: 'relative', // For overlay positioning on mobile if needed later
          }}
        >
          <Box
            component="img"
            src={CaseStudyImg}
            alt="Case Study"
            sx={{
              width: '100%',
              height: { xs: 250, md: '100%' }, // Smaller fixed height on mobile
              borderRadius: 4,
              boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
              objectFit: 'cover',
              objectPosition: '50% 25%',
            }}
          />
        </Grid>

        {/* Right Column: Bubbles */}
        <Grid item xs={12} md={7}>
          <Grid container spacing={2} sx={{ height: '100%' }}>
            {[
              { value: "$30+MM" },
              { value: ">15%" },
              { value: "2-5%" },
              { value: "5%" },
              { value: "3" },
              { value: ">5%" },
              { value: "99%" },
            ].map((item, index) => (
              <Grid
                item
                xs={index === 0 ? 12 : 6}
                md={index === 0 ? 12 : 6}
                key={index}
              >
                <Box
                  sx={{
                    backgroundColor: 'primary.dark',
                    borderRadius: 4,
                    boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
                    p: 2,
                    textAlign: 'center',
                    height: '100%',
                    color: '#fff',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="h5" sx={{ mb: 0.5, fontWeight: 'bold', color: '#fff' }}>
                    {item.value}
                  </Typography>
                  <Typography variant="body2" sx={{ fontWeight: 'medium', color: '#fff' }}>
                    {isMobile ? conciseLabels[index] : fullLabels[index]}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}