import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { _theme } from './library'; // Import theme helper
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQs() {
  return (
    <Box sx={{ py: 4, backgroundColor: 'background.default', px: { xs: 2, md: 8 } }}>
      <Typography
        variant="h3"
        sx={{ fontWeight: 'bold', mb: 3, textTransform: 'uppercase', color: 'primary.dark' }}
      >
        FAQs
      </Typography>

      {[
        {
          question: "What makes BetterLogiq different from other demand forecasting tools?",
          answer:
            "BetterLogiq uses a proprietary machine learning algorithm and multi-step process that utilizes state-of-the-art ML/AI models coupled with our teams' decades of forecasting experience to generate product-level forecasts at scale. We continuously innovate and integrate the leading-edge technology into our application and into your forecasts.",
        },
        {
          question: "How does BetterLogiq protect my company’s data?",
          answer:
            "BetterLogiq follows best practices for data security with isolated data storage and strict policies built from SOC 2 guidelines. BetterLogiq also offers the option for full isolation with single-tenant deployment.",
        },
        {
          question: "What kind of data does the tool accept?",
          answer:
            "At a minimum, the tool requires historical demand/sales data at the same time granularity to be forecast. Categorical data like product category, geography, and warehouse location can also be added. Product-specific data such as promotional periods can be uploaded as well.",
        },
        {
          question: "What time granularity is possible in BetterLogiq’s tool?",
          answer:
            "Many clients find monthly data to be granular enough for planning, however, we can generate forecasts at the weekly or daily level too, provided you have historical data at the same granularity.",
        },
        {
          question: "What is the typical improvement in forecast accuracy?",
          answer:
            "Some customers have seen more than a 50% increase in forecast accuracy across their universe of products. Typically, we find significant improvement both in aggregate and at the product level over competing tools.",
        },
        {
          question: "How does BetterLogiq’s pricing work?",
          answer:
            "Our pricing depends on a number of factors including the number of products, the time granularity, the forecast horizon, and more. Our customer care team can walk through your specific use case.",
        },
      ].map((faq, index) => (
        <Accordion
          key={index}
          sx={{
            borderRadius: 4, // UPDATED from 2 to 4
            mb: 2,
            overflow: 'hidden',
            transition: 'background-color 0.3s ease',
            '&.Mui-expanded': {
              backgroundColor: _theme().palette.primary.darkTransparent,
            },
            backgroundColor: _theme().palette.primary.backgroundGrey,
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: 'primary.dark',
                  transition: 'color 0.3s ease',
                  '.Mui-expanded &': { color: '#fff' },
                }}
              />
            }
            aria-controls={`question-${index}`}
            id={`question-${index}`}
          >
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'primary.dark', '.Mui-expanded &': { color: '#fff' } }}>
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ color: '#fff' }}>
            <Typography variant="h6" sx={{ fontWeight: 'regular' }}>
              {faq.answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}