import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { _theme } from './library';
import LandingImg from '../assets/headline_photo.jpg';
import { keyframes } from '@mui/system';
import { scroller } from 'react-scroll';

const zoomIn = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
`;

export default function Banner() {
  const handleLearnMore = () => {
    scroller.scrollTo('product', { duration: 500, smooth: true, offset: -120 });
  };

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      {/* Full-screen background image with zoom animation */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${LandingImg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          animation: `${zoomIn} 1.5s ease-in-out forwards`,
          filter: 'brightness(50%)',
        }}
      />
      
      {/* Dark overlay for even more contrast */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      />

      {/* Overlay container positioned at the bottom */}
      <Box
        sx={{
          position: 'absolute',
          bottom: { xs: '11%', md: '5%' },
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1,
          width: '100%',
          maxWidth: 1400,
          mx: 'auto',
          px: { xs: _theme().spacing(4), md: _theme().spacing(8) },
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          gap: { xs: 0, md: _theme().spacing(4) },
        }}
      >
        {/* Left Column: Headline */}
        <Box
          sx={{
            width: { xs: '100%', md: '60%' },
            textAlign: 'left',
            mb: { xs: 2, md: 0 },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontWeight: 'bold',
              color: '#fff',
            }}
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-once="true"
          >
            Actionable SKU forecasts.
            Built for complex operations.
          </Typography>
        </Box>

        {/* Right Column: Supporting text and button */}
        <Box
          sx={{
            width: { xs: '100%', md: '35%' },
            textAlign: 'left',
            mt: { xs: 0, md: 0 },
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <Typography
            variant="h5"
            sx={{ color: '#fff' }}
            data-aos="fade-up"
            data-aos-delay="600"
            data-aos-once="true"
          >
            Helping large businesses leverage AI to forecast demand at scale, drive efficiency, and increase profitability.
          </Typography>

          <Button
            variant="contained"
            sx={{
              mt: { xs: 2, md: 3 },
              backgroundColor: '#fff',
              color: '#E01A4F',
              textTransform: 'uppercase',
              fontWeight: 'bold',
              '&:hover': { backgroundColor: 'grey.200' },
            }}
            data-aos="fade-up"
            data-aos-delay="900"
            data-aos-offset="0"
            data-aos-once="true"
            onClick={handleLearnMore}
          >
            Learn More
          </Button>
        </Box>
      </Box>
    </Box>
  );
}