import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Box } from '@mui/material';
import { Element } from 'react-scroll';

import Navbar from './components/Navbar';
import Banner from './components/Banner';
import ScrollingLogos from './components/ScrollingLogos';
import ProductHighlights from './components/ProductHighlights';
import ProductPerformance from './components/ProductPerformance';
import PredictiveInsights from './components/PredictiveInsights';
import TailoredSolutions from './components/TailoredSolutions';
import AboutUs from './components/AboutUs';
import CaseStudy from './components/CaseStudy';
import FAQs from './components/FAQs';
import Footer from './components/Footer';
import { _theme } from './components/library';

// Reusable Section component for uniform spacing and side margins
const Section = ({ name, children, mb = _theme().spacing(1) }) => (
  <Box sx={{ mb }}>
    <Element name={name}>
      <Box data-aos="fade-up" sx={{ maxWidth: '1400px', mx: 'auto' }}>
        {children}
      </Box>
    </Element>
  </Box>
);

export default function Home() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
      <Navbar />
      <Banner />
      <ScrollingLogos />

      <Box
        sx={{
          p: _theme().spacing(1, 2),
          [_theme().breakpoints.up('sm')]: { p: _theme().spacing(1, 2) },
          [_theme().breakpoints.up('xl')]: { p: _theme().spacing(1, 2) },
        }}
      >
        <Section name="product">
          <ProductHighlights />
        </Section>
      </Box>

      <Element name="performance">
        <ProductPerformance />
      </Element>

      <Box
        sx={{
          p: _theme().spacing(1, 2),
          [_theme().breakpoints.up('sm')]: { p: _theme().spacing(1, 2) },
          [_theme().breakpoints.up('xl')]: { p: _theme().spacing(1, 2) },
        }}
      >
        <Section name="predictive">
          <PredictiveInsights />
        </Section>

        <Section name="tailored">
          <TailoredSolutions />
        </Section>

        <Section name="working">
          <AboutUs />
        </Section>

        <Section name="case-study">
          <CaseStudy />
        </Section>

        <Section name="questions">
          <FAQs />
        </Section>
      </Box>

      <Element name="contact">
        <Footer />
      </Element>
    </>
  );
}